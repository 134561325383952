export let line = [
    [46.509232392075766, 6.699876569921193],
    [46.50918785473712, 6.699852455981333],
    [46.508906976311685, 6.700232075329635],
    [46.50857144744769, 6.700607492333673],
    [46.50837301965221, 6.700848147039459],
    [46.508220565549145, 6.700978482530589],
    [46.50813249546839, 6.701052775620016],
    [46.508004302547796, 6.701106929626583],
    [46.507844060963706, 6.701211167281975],
    [46.50767058848011, 6.701330754549636],
    [46.50753018843058, 6.701411276186851],
    [46.50740121354395, 6.701491641622669],
    [46.507297801944105, 6.701549593170588],
    [46.50724471727362, 6.7015861724913925],
    [46.507211698422815, 6.701633510665825],
    [46.50717865266868, 6.70167671197339],
    [46.50713888842084, 6.701711730461362],
    [46.507000239981615, 6.701768782525102],
    [46.50676576967845, 6.701879544051437],
    [46.50654176342755, 6.701988782995111],
    [46.50641747698369, 6.702058049393496],
    [46.50615734441886, 6.702176053210988],
    [46.506109945290945, 6.702208416605263],
    [46.506057902050394, 6.702258770588267],
    [46.50597157416605, 6.702308212390228],
    [46.505818075876064, 6.702434980683335],
    [46.50575572948789, 6.702511675887298],
    [46.50571990764673, 6.702567324479878],
    [46.505681131023806, 6.7026078440016255],
    [46.5055967161394, 6.702658637877012],
    [46.50538145700325, 6.702795332422308],
    [46.5053199033845, 6.7028388925903295],
    [46.50518156013332, 6.702941785757034],
    [46.504951138574064, 6.703089716423105],
    [46.50465544204912, 6.703303346423849],
    [46.50437038774051, 6.703505687122188],
    [46.5042498908925, 6.703572137757754],
    [46.50419380625065, 6.703586690097461],
    [46.5041242670452, 6.70358211959994],
    [46.50404277952589, 6.703643902519645],
    [46.503972627830734, 6.703691741396156],
    [46.50380348827315, 6.703892612597742],
    [46.50366899552892, 6.7040033788572195],
    [46.50360760017821, 6.704080056275049],
    [46.503667639732825, 6.704235064019054],
    [46.503710175160904, 6.7043337720617515],
    [46.503609886283, 6.704457555779762],
    [46.50336977500732, 6.704726955795154],
    [46.50330364542591, 6.704807833096936],
    [46.50324030963734, 6.704879019581275],
    [46.503111974572676, 6.705058646388313],
    [46.502892408833226, 6.705413257401589],
    [46.50271556182846, 6.705747981256532],
    [46.502594665179934, 6.706047471310347],
    [46.5023933643697, 6.706429406235975],
    [46.50221498389709, 6.706969605156794],
    [46.50215318553124, 6.707279321681633],
    [46.5020470485675, 6.707210432106137],
    [46.50201062852329, 6.707173693798383],
    [46.50194239677809, 6.707371803052423],
    [46.501902648549354, 6.7074095715765525],
    [46.50178701461784, 6.707787580668429],
    [46.5016823055416, 6.708235765319442],
    [46.50166207662778, 6.708495274970796],
    [46.501634063094976, 6.708998466714305],
    [46.50155495283609, 6.70928221168244],
    [46.50147493409215, 6.7095728626218465],
    [46.50137546366989, 6.709948306201253],
    [46.501164566218804, 6.7106171591211465],
    [46.501053106957876, 6.710905471318757],
    [46.50095448102182, 6.711116391345109],
    [46.50085250308939, 6.711250137523007],
    [46.50082635718565, 6.711330465186199],
    [46.500748199263164, 6.7114666480671765],
    [46.500622763116134, 6.7116544852105395],
    [46.50024747415319, 6.7121482868053395],
    [46.500103821410235, 6.712168142377129],
    [46.499973623583365, 6.712356039605501],
    [46.4998862347745, 6.712537845558023],
    [46.49978326569748, 6.712666317035435],
    [46.499683859797926, 6.712755899143141],
    [46.49953608189299, 6.712875086339306],
    [46.499447104843156, 6.712957633386599],
    [46.49921495124535, 6.713133893034151],
    [46.49912219205605, 6.713220626461082],
    [46.499003602526926, 6.713288402219329],
    [46.4988341534828, 6.713396083763877],
    [46.49875539346146, 6.7134385054350325],
    [46.49857837557504, 6.7135086769123316],
    [46.49848236302573, 6.7135334047207875],
    [46.49837703222267, 6.713589970543451],
    [46.498340667169565, 6.713710416510373],
    [46.498319156640115, 6.7137713734631514],
    [46.4981599964847, 6.713897601568164],
    [46.498080530909924, 6.713978638529031],
    [46.49804470893486, 6.714012168772152],
    [46.498222373349876, 6.713800056228513],
    [46.498095815660186, 6.713704051968198],
    [46.497902918034804, 6.713503421192688],
    [46.497771945799116, 6.713420504097349],
    [46.49763893746638, 6.713338917380828],
    [46.4975049336663, 6.713344622866995],
    [46.49734719267606, 6.7134366222574595],
    [46.497036175533154, 6.713569938102872],
    [46.496953869286735, 6.713641384882791],
    [46.49668138061217, 6.71379614505415],
    [46.49663535508508, 6.713773314467993],
    [46.49647741398652, 6.713552680540676],
    [46.496292470127635, 6.713329804830443],
    [46.496133137528346, 6.713173021144071],
    [46.495988722222414, 6.713098103695832],
    [46.4958389680863, 6.713032376652958],
    [46.495743002633326, 6.713077953528789],
    [46.49564711645003, 6.713218620346457],
    [46.4956001834369, 6.713335182942215],
    [46.49559711881618, 6.713559274256257],
    [46.49556429394222, 6.7140534061180785],
    [46.49546940815318, 6.71445593430584],
    [46.495418817550615, 6.714563426115101],
    [46.49532763189704, 6.714653224682863],
    [46.49521319996856, 6.714765478488137],
    [46.495172441479035, 6.714809686312636],
    [46.49508554961306, 6.7148581993723395],
    [46.49495536288561, 6.714954641552584],
    [46.49484435944212, 6.71504656708855],
    [46.49464851108088, 6.715228945865431],
    [46.4944722004387, 6.715512664035675],
    [46.49442839545217, 6.715504469699847],
    [46.4942567344373, 6.715053465753329],
    [46.49418432057739, 6.714420076678283],
    [46.494118815126235, 6.714164345377976],
    [46.4939405371529, 6.7139793443733256],
    [46.49370354667769, 6.713495358398167],
    [46.4935989101619, 6.713310494137954],
    [46.493532478346424, 6.71319154896695],
    [46.4935167689887, 6.712985954052055],
    [46.49353792299129, 6.712777259294522],
    [46.49354988066054, 6.712722808044346],
    [46.4933493956222, 6.712742432705891],
    [46.49317880328786, 6.712794219946989],
    [46.493064164745185, 6.712873912108287],
    [46.492957413416214, 6.712920934235323],
    [46.49284729740438, 6.71300447276831],
    [46.49274910017209, 6.71312301986736],
    [46.492632346183676, 6.713293917309486],
    [46.49258161503245, 6.7133792632083145],
    [46.492562650348404, 6.713508469463121],
    [46.492584955264135, 6.713620189237411],
    [46.492653085573764, 6.713864154552416],
    [46.49269092344643, 6.714013440377902],
    [46.492754064271864, 6.714180623101754],
    [46.492776218733866, 6.714268899663361],
    [46.49268917116129, 6.7145839796188715],
    [46.49263422420858, 6.714713667391194],
    [46.49241077222348, 6.7150571165837105],
    [46.49227545777405, 6.715280358534797],
    [46.49223091376388, 6.715348686266017],
    [46.49217989856798, 6.715389747195877],
    [46.492079183040794, 6.715536977866065],
    [46.491967963741224, 6.715729936955989],
    [46.4918282333786, 6.71596625966143],
    [46.491783597635674, 6.716020259705518],
    [46.49166518441007, 6.7160726404159625],
    [46.49158237852148, 6.716065931730278],
    [46.49153292137996, 6.716069197611821],
    [46.49139534422542, 6.7160788509913685],
    [46.49134426235786, 6.716109491368891],
    [46.49112358225602, 6.71643448103125],
    [46.49090243343603, 6.7167409179142865],
    [46.490789429622694, 6.716936499129107],
    [46.49059004120302, 6.717244478572551],
    [46.490454826516675, 6.717342666819088],
    [46.49031792040954, 6.717457809499712],
    [46.49008326769668, 6.717763115133069],
    [46.48995514937255, 6.717845576803679],
    [46.489801342244434, 6.717990899354731],
    [46.489732716437075, 6.718092104487424],
    [46.489654684098156, 6.718270280632152],
    [46.489595455536254, 6.7183620198708445],
    [46.48953644283218, 6.7184188121132955],
    [46.489361122961235, 6.718718109418457],
    [46.4891859097756, 6.7190343353667155],
    [46.48901168612996, 6.719364873135139],
    [46.48888521798731, 6.719565830837383],
    [46.48862987526334, 6.719957992953602],
    [46.48848503607651, 6.720241245968129],
    [46.48829995220564, 6.720726382548562],
    [46.48818584637698, 6.721139381788909],
    [46.48814363081039, 6.721576258267381],
    [46.48818953391877, 6.722006756605165],
    [46.488204377098704, 6.722647359551019],
    [46.48820485716128, 6.723293362544073],
    [46.48823087804246, 6.723567832927172],
    [46.48832226339806, 6.7239365177929855],
    [46.48838820311583, 6.724121895616928],
    [46.48836789495281, 6.724325344877655],
    [46.48836903540784, 6.724934872622377],
    [46.48839956193069, 6.7254971249313265],
    [46.488433942049724, 6.725671198964914],
    [46.48852460007019, 6.725925283057872],
    [46.488692315174916, 6.726416701052368],
    [46.488820113836525, 6.726855246855642],
    [46.48886581712203, 6.727257103432656],
    [46.488837202545746, 6.727571370954853],
    [46.48887162031959, 6.7277519594425526],
    [46.4888293088127, 6.727890575609624],
    [46.488652057058104, 6.728316200860165],
    [46.488501783557474, 6.728596897820379],
    [46.4886665847218, 6.7290558007524375],
    [46.48865370323882, 6.7291536533795036],
    [46.488624605787805, 6.729247811333236],
    [46.488610473215, 6.729322631249207],
    [46.48862054402147, 6.729494422189797],
    [46.48865533339802, 6.729734918873706],
    [46.48867296324868, 6.72995271163289],
    [46.4888183400043, 6.730327215088671],
    [46.488754469315865, 6.73047132119107],
    [46.488524295481795, 6.730779107418617],
    [46.48839687132246, 6.7309605122734855],
    [46.488240847050456, 6.731150177646426],
    [46.48813971855664, 6.731269949701099],
    [46.487969566977064, 6.731537871308192],
    [46.487898311741745, 6.731652114360767],
    [46.48796771514989, 6.731817918224519],
    [46.48795501151706, 6.73208898987559],
    [46.48806660266232, 6.732239916635414],
    [46.48803746207748, 6.732327560275891]
];
